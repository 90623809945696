<template>
  <main class="main">
    <section class="section-galeria">
      <div class="galeria" id="gallery">
        <a
          :href="getImages(gallery.img)"
          rel="noreferrer"
          v-for="(gallery, key) in gallery"
          :key="key"
          data-pswp-width="2500"
          data-pswp-height="1666"
        >
          <img :src="getImages(gallery.img)" :alt="gallery.title" />
          <span class="galeria-title text-wrap">
            {{ gallery.title }}
          </span>
        </a>
      </div>
    </section>
  </main>
</template>

<script>
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

import gallery from "@/assets/js/json/gallery.json";
import { getImages } from "@/core/Utils";

export default {
  name: "GalleryView",
  components: {},
  data: () => ({
    gallery,
    lightbox: null,
  }),
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",

        children: "a",
        secondaryZoomLevel: "fit",

        imageClickAction: "close",
        tapAction: "close",

        // tap delay is removed if set to false
        doubleTapAction: false,
        pswpModule: () => import("photoswipe"),
      });
      this.lightbox.init();
      this.lightbox.on("beforeOpen", () => {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      });
      this.lightbox.on("beforeOpen", () => {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      });
      this.lightbox.on("close", () => {
        document.getElementsByTagName("body")[0].style.overflow = "auto";
      });
      const _lightbox = this.lightbox;
      this.lightbox.on("uiRegister", function () {
        _lightbox.pswp.ui.registerElement({
          name: "custom-caption",
          order: 9,
          isButton: false,
          appendTo: "root",
          html: "Caption text",
          onInit: (
            el,
            // eslint-disable-next-line  no-unused-vars
            pswp
          ) => {
            _lightbox.pswp.on("change", () => {
              const currSlideElement = _lightbox.pswp.currSlide.data.element;
              let captionHTML = "";
              if (currSlideElement) {
                const hiddenCaption = currSlideElement.querySelector(
                  ".hidden-caption-content"
                );
                if (hiddenCaption) {
                  // get caption from element with class hidden-caption-content
                  captionHTML = hiddenCaption.innerHTML;
                } else {
                  // get caption from alt attribute
                  captionHTML = currSlideElement
                    .querySelector("img")
                    .getAttribute("alt");
                }
              }
              el.innerHTML = captionHTML || "";
            });
          },
        });
      });
    }
  },
  methods: {
    getImages,
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
};
</script>
<style scoped>
.section-galeria {
  display: flex;
  justify-content: center;
  align-items: center;
}

.galeria {
  display: flex;
  width: 90% !important;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.galeria a {
  display: inline-block;
  text-align: center;
  width: 400px;
  height: 200px;
  margin: 10px;
  position: relative;
}

.galeria a img + .galeria-title {
  display: none;
  position: relative;
  transition: all 0.2s linear;
}

.galeria a img + .galeria-title::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-color: var(--color-primary);
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
}

.galeria a:hover img + .galeria-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  right: 0;
  padding: 15px;
  color: #fff;
  transform: scale(1.02);
}

img {
  transition: all 0.2s linear;
}

.galeria a:hover img {
  /* box-shadow: 0px 2px 10px #8d8d8d; */
  transform: scale(1.02);
}

.galeria img {
  display: block;
  width: 100%;
  height: 100%;
  border: 3px solid var(--color-primary);
  border-radius: 2px;
  position: relative;
}
</style>
<style>
.pswp__custom-caption {
  background: var(--color-primary);
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}
.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
.hidden-caption-content {
  display: none;
}
</style>
