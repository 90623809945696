<template>
  <main class="main">
    <section class="section">
      <div>
        <h2>História</h2>
      </div>
      <div class="quem-somos-historia">
        <p>
          A Acquatool Consultoria nasce em 1997 na cidade de João Pessoa, um ano
          mais tarde instala-se definitivamente na Cidade de Fortaleza. Seus
          sócios fundadores, ambos na época com mestrado na área de recursos
          hídricos, estabeleceram como objetivos da empresa o fornecimento de
          soluções de engenharia para nossos clientes nas áreas de planejamento,
          viabilidade, estudos, planos e projetos de infraestrutura hídrica,
          saneamento e meio ambiente fundamentalmente para o setor público, mas
          também para o setor privado. Durante os primeiros anos, a Acquatool
          dedicou-se à elaboração de planos e projetos na área de hidrologia e
          suas aplicações nos Estados da Paraíba e do Ceará.
        </p>

        <p>
          Empresa de pequeno porte, mas com capacidade e perspectiva de ampliar
          seu perfil de atendimento, a Acquatool incorporou profissionais de
          outras áreas e estabeleceu contratos de prestação de serviços com
          consultores independentes nas áreas de geologia, topografia,
          cartografia e geoprocessamento, arqueologia, geografia, e ciências
          sociais.
        </p>

        <p>
          Verificando a possibilidade de atuação em outros estados do nordeste,
          como Rio Grande do Norte e Pernambuco, passou a trabalhas através de
          contratos com esses estados e diversas prefeituras municipais.
        </p>

        <p>
          Em 2013 incorpora como sócios, além do seu sócio fundador Engenheiro
          Hídrico e mestre em recursos hídricos, uma profissional mestre e
          doutora em Sociologia (Consultora em Comunidades e Meio Ambiente) e um
          Engenheiro Civil, hoje mestre em Engenharia Oceânica e doutorando na
          mesma área (com data de defesa de tese para julho de 2020).
        </p>

        <p>
          Atualmente possui como responsáveis técnicos: um profissional
          engenheiro hídrico com mestrado em recursos hídricos e saneamento
          (diretor técnico e responsável legal da empresa); um engenheiro civil
          com mestrado em engenharia oceânica e área de concentração em
          engenharia costeira, um engenheiro civil mestrando em recursos
          hídricos e saneamento, um engenheiro civil especialista em engenharia
          ambiental e saneamento básico, um geólogo e um geografo mestre em
          geografia com área de concentração em análise geoambiental e ordenação
          do territórios em áreas semiáridas e litorâneas.
        </p>

        <p>
          Possui inscrição empresarial e dos seus responsáveis técnicos e
          consultores no Certificado de Regularidade emitido pelo Cadastro
          Técnico Federal do IBAMA – Instituto Brasileiro de Meio Ambiente e dos
          Recursos Naturais renováveis. Possui também inscrição no Cadastro
          Estadual de Atividades de Defesa Ambiental – Categoria: Consultoria
          Técnico Ambiental.
        </p>

        <p>
          A empresa funciona de forma em duas salas amplias e dispõe de local
          apropriado para a realização de reuniões, inclusive podendo utilizar
          as instalações de auditório com infraestrutura completa no shopping
          onde localizam-se suas salas, caso seja necessária a realização de
          eventos com maior número de pessoas.
        </p>

        <p>
          Neste sentido, a Acquatool Consultoria é uma empresa concebida sob uma
          ótica contemporânea, contando com uma estrutura fixa racionalizada e
          ágil e uma capacidade de ampliação e diversificação de seu campo de
          atuação, baseada num extenso portfólio de consultores e disponíveis e
          em condições contratuais para participar de projetos específicos. Em
          matéria tecnológica, dispõe de recursos modernos, tanto na área da
          engenharia, utilizando softwares modernos desenvolvidos pela própria
          empresa, como também recursos gráficos informatizados de última
          geração (cartografia digital, classificação automática de imagens,
          entre outros).
        </p>
      </div>
    </section>

    <section class="section">
      <div>
        <h2>Sócios e Responsáveis Técnicos</h2>
      </div>
      <div>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Nome do profissional</th>
              <th>Formação</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-middle">Pedro Antônio Molinas</td>
              <td>
                <ul>
                  <li>Engenheiro Hídrico</li>
                  <li>Mestre em Recursos Hídricos e Saneamento</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Ernesto Monilas</td>
              <td>
                <ul>
                  <li>Engenheiro Civil</li>
                  <!-- <li>Mestre em Engenharia Oceânica</li>
                  <li>Dr. em Engenharia Oceânica</li> -->
                  <li>
                    Mestre e Doutor em Engenharia Oceânica – Área de
                    Concentração Engenharia Costeira
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Alejandra Silvia Bentolila</td>
              <td>
                <ul>
                  <li>Dra. em sociologia</li>
                  <li>Consultora em Comunidades e Meio Ambiente</li>
                </ul>
              </td>
            </tr>

            <!-- <tr>
              <td>Lucas Falcão Muniz</td>
              <td>
                <ul>
                  <li>Engenheiro Civil</li>
                </ul>
              </td>
            </tr> -->

            <tr>
              <td class="align-middle">Guilherme Ramalho Gomez</td>
              <td>
                <ul>
                  <li>Engenharia Civil</li>
                  <li>
                    Especialista em Engenharia Ambiental e Saneamento Básico
                  </li>
                  <li>Mestre em Recursos Hídricos e Saneamento</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>Raimundo Roncy de Oliveira</td>
              <td>
                <ul>
                  <li>Geólogo</li>
                </ul>
              </td>
            </tr>

            <!-- <tr>
              <td class="align-middle">Filipe Maciel de Moura</td>
              <td>
                <ul>
                  <li>Geografo</li>
                  <li>
                    Mestre em Análise Geoambiental e Orientação do Território
                    nas Regiões Semi-Áridas e Litorâneas
                  </li>
                </ul>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "QuemSomosView",
  components: {},
};
</script>
<style scoped></style>
