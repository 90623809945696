<template>
  <main class="main">
    <SlideView />

    <div
      class="alert alert-warning"
      role="alert"
      v-for="(alert, key) of alerts"
      :key="key"
      v-html="alert.msg"
    ></div>

    <!--Quem somos-->
    <section class="section" id="quem-somos">
      <div>
        <h2>Quem Somos</h2>
      </div>
      <div class="quem-somos">
        <p class="quem-somos-text">
          ACQUATOOL CONSULTORIA é uma empresa de Serviços de Consultoria com
          especialização em temas relacionados à Recursos Hídricos, Saneamento e
          Meio Ambiente. Consolida-se na região nordeste do Brasil, mas possui
          atuação em vários estados brasileiros localizados no norte, sul e
          sudeste.
        </p>

        <p class="quem-somos-text">
          Nasce no Nordeste Brasileiro com o propósito expresso de oferecer o
          consultoria com profissionais capacitados nas suas áreas específicas,
          mas com desenvolvimento de expertise transdisciplinar e desempenho
          administrativo e tecnológico adaptável às mais diversas situações e
          condições de contratação para executar serviços especializados em área
          específica que surge também da adopção de abordagens intrinsicamente
          transdisciplinar - Recursos Hídricos, Saneamento e Meio Ambiente.
        </p>
        <div class="link-saiba-mais">
          <a href="/quem-somos" class="link-decoration-none">Clique aqui</a>
          para conhecer nossa
          <b
            ><strong>história</strong> e
            <strong>nossa equipe técnica</strong>.</b
          >
        </div>
      </div>
    </section>

    <!--Projetos-->
    <ProjetosView />

    <!--Serviços-->
    <ServicosView />

    <!--Nossos clientes-->
    <ClientesView />

    <!--Endereço-->
    <EnderecoView :showMaps="true" />
  </main>
</template>

<script>
import SlideView from "@/components/SlideView.vue";
import ProjetosView from "@/components/ProjetosView.vue";
import ServicosView from "@/components/ServicosView.vue";
import ClientesView from "@/components/ClientesView.vue";
import EnderecoView from "@/components/EnderecoView.vue";

import alerts from "@/assets/js/json/alerts.json";

export default {
  name: "HomeView",
  components: {
    SlideView,
    ProjetosView,
    ServicosView,
    ClientesView,
    EnderecoView,
  },
  data: () => ({
    alerts,
  }),
};
</script>
<style scoped></style>
