<template>
  <section class="section" id="servicos">
    <div>
      <h2>Serviços</h2>
    </div>

    <div class="accordion" id="accordion">
      <div class="accordion-item" v-for="(servico, key) of servicos" :key="key">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            :class="{ collapsed: key > 0 }"
            type="button"
            data-bs-toggle="collapse"
            :aria-expanded="key === 0"
            :data-bs-target="`#collapse-${key}`"
            :aria-controls="`collapse-${key}`"
          >
            {{ servico.title }}
          </button>
        </h2>
        <div
          :id="`collapse-${key}`"
          class="accordion-collapse collapse"
          :class="{ show: key === 0 }"
          :data-bs-parent="`#accordion`"
        >
          <div class="accordion-body">
            <ul class="list-margin">
              <li v-for="(detail, key) of servico.services" :key="key">
                {{ detail }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import servicos from "@/assets/js/json/servicos.json";

export default {
  name: "ServicosView",
  data: () => ({
    servicos,
  }),
};
</script>
<style scoped></style>
