<template>
  <section class="section" :class="classSection">
    <h2 v-if="showMaps">Endereço</h2>
    <div class="andress">
      <div class="maps" v-if="showMaps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.353453496516!2d-38.50120748554481!3d-3.732913697284119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74863bb000001%3A0xbbb33829203a06c0!2sAvenida%20Shopping%20e%20Office!5e0!3m2!1spt-BR!2sbr!4v1594922943109!5m2!1spt-BR!2sbr"
          width="100%"
          height="100%"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          class="loading"
          tabindex="0"
        >
        </iframe>
      </div>
      <div class="text-andress" :class="{ 'boder-left': showMaps }">
        <p>
          <b>ACQUATOOL CONSULTORIA S/S LTDA - EPP</b><br />
          <b>CNPJ:</b> 02.042.399/0001-07 <br />
        </p>
        <p>
          <b>Matriz</b> <br />
          Rua Calixto Machado, nº 21, Sala 84<br />
          Pires Façanha, Eusébio/CE, 61.760-000<br />
        </p>
        <p>
          <b>Escritório e Correspondência</b> <br />
          Av. Dom Luís, 300 Sala 1002/1003<br />
          Meireles, Fortaleza - CE, 60160-230<br />
        </p>
        <div>
          <h3>Telefones</h3>

          <div>
            <i class="icon-mobile"></i>
            <span>
              <a
                class="link-decoration-none text-dark"
                target="_blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Whatsapp"
                href="https://api.whatsapp.com/send?phone=5585999949603"
              >
                (85) 9.9994-9603</a
              >
            </span>
          </div>
          <div>
            <i class="icon-phone"></i>
            <span data-toggle="tooltip" data-placement="right" title="Fixo"
              >(85) 3264-9700</span
            >
          </div>
        </div>

        <div>
          <h3>E-mail</h3>
          <div class="emais-text">
            <i class="icon-envelop"></i>
            <a
              href="mailto:acquatoolconsultoria@gmail.com"
              class="link-decoration-none"
              ><span data-toggle="tooltip" data-placement="right" title="E-mail"
                >acquatoolconsultoria@gmail.com</span
              ></a
            >
          </div>
          <div class="emais-text">
            <i class="icon-envelop"></i>
            <a
              href="mailto:molinas.pedro@gmail.com"
              class="link-decoration-none"
              ><span data-toggle="tooltip" data-placement="right" title="E-mail"
                >molinas.pedro@gmail.com</span
              ></a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "EnderecoView",
  props: {
    showMaps: Boolean,
    classSection: String,
    default: () => true,
  },
};
</script>
<style scoped>
.boder-left {
  border-left: unset !important;
}
.andress {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: nowrap;
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  max-height: 600px;
}

div.andress .maps {
  width: 50%;
  /* height: 100%; */
}

.text-andress {
  margin: 0 20px;
  border-left: 3px solid var(--color-background);
  padding: 10px;
  order: 2;
}

.andress div.maps,
.andress div.text-andress {
  height: 400px;
}

.emais-text {
  display: flex;
  align-items: center;
}

.emais-text a > span {
  margin-left: 5px;
}
</style>
