<template>
  <!--Header-->
  <header class="header">
    <input type="checkbox" id="checkbox" />
    <label for="checkbox" ref="checkboxHambuger">
      <span class="checkbox-icon"></span>
    </label>
    <div class="header-logo">
      <a href="/">
        <img
          class="header-logo-img"
          src="../../assets/img/logo_acqua.svg"
          title="Acquatool"
          alt="Logo acquatool"
        />
      </a>
    </div>
    <nav class="nav">
      <ul class="nav-menu color-text">
        <li v-for="(link, key) of links" :key="key">
          <a
            class="color-text"
            :class="{ 'actived-link': linkActivate === link.id }"
            :href="link.ref"
            @click="onActivated({ id: link.id, onClick: link.onClick })"
            >{{ link.description }}</a
          >
        </li>
      </ul>
    </nav>
    <!--fim Nav-->
  </header>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "HeaderView",
  components: {},
  data: () => ({
    linkActivate: "index",
  }),
  methods: {
    onActivated({ id, onClick }) {
      this.linkActivate = id ? id : "index";
      this.$refs.checkboxHambuger.click();
      if (typeof onClick !== "undefined") {
        onClick();
      }
    },
    onClickPublicacao() {
      Swal.fire({
        title: "Atenção",
        text: "Você será redirecionado para o site 'academia.edu'.",
        icon: "warning",
        buttons: {
          cancel: "sair",
          ok: {
            text: "Ir",
            value: "ok",
          },
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.open("https://independent.academia.edu/PedroMolinas");
        }
      });
    },
  },
  computed: {
    links() {
      return [
        {
          ref: "/",
          id: "index",
          description: "Pagina inicial",
        },
        {
          ref: "/#quem-somos",
          id: "quemSomos",
          description: "Quem somos",
        },
        {
          ref: "/#projetos",
          id: "projetos",
          description: "Projetos",
        },
        {
          ref: "/#servicos",
          id: "servicos",
          description: "Serviços",
        },
        {
          ref: "/#clientes",
          id: "clientes",
          description: "Nossos clientes",
        },
        {
          ref: "#publicacoes",
          id: "",
          description: "Publicações",
          onClick: this.onClickPublicacao,
        },
        {
          ref: "galeria",
          id: "galeria",
          description: "Galeria de fotos",
        },
        {
          ref: "contato",
          id: "contato",
          description: "Contato",
        },
      ];
    },
  },
};
</script>

<style scoped>
.header {
  height: 12.1em;
  max-width: 1908px;
  background-image: url("../../assets/img/header.svg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--color-secundary);
  position: relative;
}

.header-logo-img {
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 10px;
  width: 200px;
  height: 127px;
  z-index: 100;
}

.nav {
  position: relative;
  z-index: 99;
  background-color: transparent;
  width: 80%;
  transition: all 0.5s linear;
}

.nav .nav-menu,
.nav-menu ul {
  display: inline-block;
  list-style: none;
  position: relative;
}

.nav ul li {
  margin-left: 1.25em;
  margin-top: 0.625em;

  text-align: center;
  float: left;
}

.nav a {
  padding: 10px;
  display: block;
  text-decoration: none;
  text-align: center;
  color: var(--color-secundary);
}

.nav .nav-menu ul {
  background-color: #2275a5d8;
  border-radius: 2px;
}

.nav a:hover {
  background-color: #f4f4f4;
  color: #555;
  border-radius: 4px;
}

.nav .nav-menu ul li {
  float: none;
  border-bottom: solid 1px #ccc;
  margin-left: 0;
  padding: 10px;
}

.nav .nav-menu ul li:last-child {
  margin-bottom: 0;
}

label[for="checkbox"] {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px;
  background-color: var(--color-primary);
  color: var(--color-secundary);
  text-align: center;
  left: 10px;
  top: 10px;
  font-size: 1.875em;
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: none;
}

label[for="checkbox"] > .checkbox-icon {
  position: relative;
  display: inline-block;
  min-width: 90%;
  min-height: 8px;
  margin-bottom: 12px;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: 101;
}

label[for="checkbox"] > .checkbox-icon::before {
  position: absolute;
  content: "";
  display: inline-block;
  min-height: 8px;
  min-width: 100%;
  margin-bottom: 50px;
  top: -14px;
  left: 0;
  background-color: #fff;
  z-index: 101;
  transition: all 0.5s ease;
}

label[for="checkbox"] > .checkbox-icon::after {
  position: absolute;
  content: "";
  display: inline-block;
  min-height: 8px;
  min-width: 100%;
  margin-bottom: 50px;
  top: 14px;
  left: 0;
  background-color: #fff;
  z-index: 101;
  transition: all 0.5s ease;
}

#checkbox {
  display: none;
}

@media screen and (max-width: 850px) {
  label[for="checkbox"] {
    display: block;
    transition: all 0.5s;
    z-index: 1000;
  }

  #checkbox:checked + label[for="checkbox"] > .checkbox-icon {
    background-color: transparent;
  }

  #checkbox:checked + label[for="checkbox"] > .checkbox-icon::after {
    transform: translateY(-12px) rotate(45deg);
  }

  #checkbox:checked + label[for="checkbox"] > .checkbox-icon::before {
    transform: translateY(15px) rotate(-45deg);
  }

  #checkbox:checked ~ .nav {
    margin-left: 0;
  }

  .nav {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100%;
    transition: all 0.4s;
    top: 0;
    background-color: var(--color-primary);
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  .nav .nav-menu li {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .nav .nav-menu ul {
    position: static;
    overflow: hidden;
    max-height: 0;
    transition: all 0.4s;
  }

  .nav .nav-menu li:hover ul {
    height: auto;
    max-height: 220px;
  }

  .header-logo-img {
    width: 100px;
    right: 30px;
    z-index: 98;
  }
}
</style>
