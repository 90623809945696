<template>
  <!--Slide-->
  <section class="section">
    <div
      id="carousel-principal"
      class="carousel slide loading carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carousel-principal"
          :data-bs-slide-to="key"
          :aria-current="key === 0"
          :aria-label="slide.alt"
          :class="{ active: key === 0 }"
          v-for="(slide, key) of slides"
          :key="key"
        ></button>
      </div>

      <div class="carousel-inner">
        <div
          class="carousel-item"
          data-bs-interval="3000"
          :class="{ active: key === 0 }"
          v-for="(slide, key) of slides"
          :key="key"
        >
          <img :src="getImages(slide.img)" class="d-block" :alt="slide.alt" />

          <div class="carousel-caption d-none d-md-block">
            <h5>{{ slide.legenda }}</h5>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carousel-principal"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Anterior</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carousel-principal"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Próximo</span>
      </button>
    </div>
  </section>
</template>
<script>
import { Carousel } from "bootstrap";
import { getImages } from "@/core/Utils";
export default {
  name: "SlideView",
  components: {},
  mounted() {
    new Carousel("#carousel-principal", {
      ride: true,
    });
  },
  data: () => ({
    slides: [
      {
        img: "assets/img/obrasHidraulicas.jpg",
        legenda: "Obras Hidráulicas.",
        alt: "Imagem do Slide: Obras Hidraulicas",
      },
      {
        img: "assets/img/agua-recurso-hidrico.jpg",
        legenda: "Hidrologia e Hidráulica Computacional.",
        alt: "Imagem do Slide: Recursos Hidricos",
      },
      {
        img: "assets/img/estudos-ambiente.jpg",
        legenda: "Estudos Ambientais.",
        alt: "Imagem do Slide: Estudo Ambientais",
      },
      {
        img: "assets/img/planejamento.jpg",
        legenda: "Planejamento e Gestão em Recursos Hídricos.",
        alt: "Imagem do Slide: Planejamento",
      },
    ],
  }),
  methods: {
    getImages,
  },
};
</script>

<style scoped>
#carousel-principal {
  width: 100%;
  height: 481px;
  max-height: 500px;
  margin-top: 40px;
  border-radius: 10px;
}
.carousel-caption {
  background-color: #0000009d;
  border-radius: 5px;
  margin-bottom: 20px;
}

.carousel-caption h5 {
  color: #fff;
  margin-bottom: 0;
}

.carousel-item img {
  width: 100%;
  height: 100vh;
  max-height: 481px;
  border-radius: 10px;
}
@media screen and (max-width: 770px) {
  #carousel-principal {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
</style>
