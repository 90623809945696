<template>
  <div id="app">
    <LoaderView />
    <HeaderView />
    <router-view />
    <FooterView />
  </div>
</template>

<script>
import LoaderView from "@/components/Utils/LoaderView.vue";
import HeaderView from "@/components/Utils/HeaderView.vue";
import FooterView from "@/components/Utils/FooterView.vue";

export default {
  name: "HomeView",
  components: { LoaderView, HeaderView, FooterView },
};
</script>

<style>
@import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "/node_modules/slick-carousel/slick/slick.css";
@import "/node_modules/slick-carousel/slick/slick-theme.css";

@import "@/assets/css/normalize.css";
@import "@/assets/css/icons.css";
@import "@/assets/css/style.css";
</style>
