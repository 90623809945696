<template>
  <div class="modal modal-lg fade" tabindex="-1" :id="id">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <p v-html="body"></p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalView",
  props: {
    title: {
      type: String,
    },
    id: {
      type: String,
    },
    body: {
      type: String,
    },
  },
};
</script>
<style scoped>
.modal-title {
  font-size: 17px !important;
}
.modal-body {
  font-size: 15px !important;
}
</style>
