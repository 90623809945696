<template>
  <div>
    <div class="container-topo-whatsapp">
      <!--Topo-->
      <div class="topo">
        <a href="#top" class="topo-image"></a>
      </div>

      <!--Whatsapp-->
      <div class="whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=5585999949603"
          target="_blank"
          id="whatsapp"
          ><span class="icon-whatsapp"></span
        ></a>
      </div>
    </div>

    <!--Livro Dr. Molinas-->
    <div
      class="livro"
      data-toggle="tooltip"
      data-placement="bottom"
      title="GESTÃO E OPERAÇÃO DO PROJETO DE INTEGRAÇÃO DO RIO SÃO FRANCISCO COM BACIAS HIDROGRÁFICAS DO NORDESTE SETENTRIONAL"
    >
      <a
        href="https://drive.google.com/file/d/1WbP6rUXRp6aJTZzV1EbI_1SL7eqyu0h9/view?usp=sharing"
        target="_blank"
        class="livro-image"
      ></a>
    </div>
    <!--footer-->
    <footer class="footer color-text">
      <p>
        Acquatool Consultoria S/S LTDA EPP - CNPJ 02.042.399/0001-07 - Endereço
        Rua Calixto Machado, nº 21, Sala 84 Pires Façanha, Eusébio/CE - CEP
        61.760-000 - Escritório e Correspondência Av. Dom Luís, 300 Sala
        1002/1003 Meireles, Fortaleza - CE, CEP 60160-230
      </p>
      <!-- <div class="footer-contacts">
        <ul class="footer-list-contact">
          <li class="hover">
            <i class="icon-phone"></i>
            <span data-toggle="tooltip" data-placement="right" title="Fixo"
              >(85) 3264-9700</span
            >
          </li>
          <li class="hover">
            <i class="icon-mobile"></i>
            <span>
              <a
                class="hover link-decoration-none color-text"
                target="_blank"
                data-toggle="tooltip"
                data-placement="right"
                title="Whatsapp"
                href="https://api.whatsapp.com/send?phone=5585999949603"
              >
                (85) 9.9994-9603</a
              >
            </span>
          </li>
          <li class="hover">
            <i class="icon-envelop"></i>
            <a
              href="mailto:acquatoolconsultoria@gmail.com"
              class="hover link-decoration-none color-text"
              ><span data-toggle="tooltip" data-placement="right" title="E-mail"
                >acquatoolconsultoria@gmail.com</span
              ></a
            >
          </li>
          <li class="hover">
            <i class="icon-envelop"></i>
            <a
              href="mailto:molinas.pedro@gmail.com"
              class="hover link-decoration-none color-text"
              ><span data-toggle="tooltip" data-placement="right" title="E-mail"
                >molinas.pedro@gmail.com</span
              ></a
            >
          </li>
        </ul>
      </div> -->
      <div class="footer-copy text-center text-size-small">
        <p>
          Copyright&copy;
          {{ date }}
          Acquatool consultoria S/S LTDA EPP - Todos os direitos reservados.
        </p>
      </div>
    </footer>
    <!--fim footer-->
  </div>
</template>

<script>
export default {
  name: "FooterView",
  components: {},
  data: () => ({
    date: new Date().getFullYear(),
  }),
  mounted() {
    // eslint-disable-next-line no-undef
    $(function () {
      // eslint-disable-next-line no-undef
      $(window).scroll(function () {
        // eslint-disable-next-line no-undef
        if ($(this).scrollTop() > 100) {
          // eslint-disable-next-line no-undef
          $(".topo").fadeIn();
        } else {
          // eslint-disable-next-line no-undef
          $(".topo").fadeOut();
        }
      });
      // eslint-disable-next-line no-undef
      $('a[href="#top"]').click(function () {
        // eslint-disable-next-line no-undef
        $("html, body").animate({ scrollTop: 0 }, 800);
        return false;
      });
    });

    window.addEventListener("scroll", this.scrollWhatsappScrollTop);
  },
  methods: {
    //pega o tamanho do documento
    getDocHeight() {
      var D = document;
      return Math.max(
        D.body.scrollHeight,
        D.documentElement.scrollHeight,
        D.body.offsetHeight,
        D.documentElement.offsetHeight,
        D.body.clientHeight,
        D.documentElement.clientHeight
      );
    },
    //move o botao do whatsapp e do scroll top para ficar acima do footer
    scrollWhatsappScrollTop() {
      // const whatsapp = document.querySelector(".whatsapp");
      // const topo = document.querySelector(".topo");
      const whatsappTop = document.querySelector(".container-topo-whatsapp");
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const footerHeiht =
        document.querySelector(".footer").clientHeight ||
        document.querySelector(".footer").offsetHeight;
      if (scrollTop + window.innerHeight === this.getDocHeight()) {
        // whatsapp.style.transform = `translateY(-${footerHeiht}px)`;
        // topo.style.transform = `translateY(-${footerHeiht}px)`;
        whatsappTop.style.transform = `translateY(-${footerHeiht}px)`;
      } else {
        // whatsapp.style.transform = `translateY(0)`;
        // topo.style.transform = `translateY(0)`;
        whatsappTop.style.transform = `translateY(0)`;
      }
    },
  },
};
</script>

<style scoped>
.container-topo-whatsapp {
  display: flex;
  position: fixed;
  bottom: 10px;
  transition: all 0.2s linear;
  z-index: 99;
  padding: 0px 5px;
  flex-direction: column;
  flex-wrap: wrap;
  right: 0;
}

.whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1ebea5;
  position: relative;
  /* bottom: 1.25em; */
  /* right: 1.0625em; */
  color: #fff;
  z-index: 98;
  transition: all 0.2s linear;
  /* margin: 0px 5px; */
  box-shadow: 0px 3px 10px #525151a1;
}

.whatsapp a {
  text-decoration: none;
}

.whatsapp a:hover {
  text-decoration: none;
}

.whatsapp > a > span {
  display: block;
  color: #fff;
  font-size: 1.5em;
}

.whatsapp:hover,
.topo:hover {
  box-shadow: 0px 3px 3px #525151a1;
}

.topo {
  position: relative;
  padding: 10px;
  /* bottom: 80px; */
  /* right: 21px; */
  display: none;
  font-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: var(--color-primary);
  transition: all 0.5s linear;
  z-index: 99;
  box-shadow: 0px 3px 10px #525151a1;
  margin-bottom: 10px;
}
.topo-image {
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/icons/arrow.svg");
  transform: rotate(90deg);
  background-size: cover;
}

a[href="#topo"]:hover,
a[id="#whatsapp"]:hover {
  text-decoration: none;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: var(--color-primary);
  box-sizing: border-box;
  padding: 1.5em;
  margin-top: 2.5em;
  font-size: 13px;
}

.footer p {
  width: 100%;
  text-align: center;
}

.footer .footer-contacts .footer-list-contact {
  list-style-type: none;
  padding: 0;
}
.footer .footer-list-contact li {
  margin-top: 0.6em;
  line-height: 0.4em;
}

.footer-andress {
  line-height: 1.5em;
}

.footer-copy {
  align-self: flex-end;
}

.footer-copy p {
  margin-bottom: 0;
}
</style>
