<template>
  <section class="section" id="projetos">
    <div>
      <h2>Projetos</h2>
    </div>
    <div class="projetos-cards">
      <div v-for="(projeto, key) of projetos" :key="key">
        <div class="card card-projetos">
          <div class="card-body text-center text-vertical-center">
            <h5 class="card-title">{{ projeto.title }}</h5>
          </div>
          <div class="card-footer">
            <a
              data-bs-toggle="modal"
              :data-bs-target="`#card-${key}`"
              :id="`#card-${key}-button`"
              class="btn btn-link link-decoration-none"
            >
              Ver mais ...
            </a>
            <span
              class="badge text-bg-primary"
              v-if="projeto.isNew"
              style="margin-left: 0.5em"
              >Novo</span
            >
          </div>
        </div>
      </div>
    </div>
    <ModalView
      :id="`card-${key}`"
      :body="projeto.body"
      :title="projeto.title"
      v-for="(projeto, key) of projetos"
      :key="key"
    />
  </section>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import slickCarousel from "slick-carousel";
import projetos from "@/assets/js/json/projetos-cards.json";
import ModalView from "@/components/Utils/ModalView.vue";

export default {
  name: "ProjetosView",
  components: { ModalView },
  data: () => ({
    projetos: [],
  }),
  created() {
    this.projetos = projetos.map((projeto) => {
      let body = `<b>Cliente: </b>${projeto.customer}<br/><br/>`;

      body += "<b>Objetivo do empreendimento: </b> </b><br />";

      if (Array.isArray(projeto.objective)) {
        body +=
          " <ul class='list-margin text-justify' style='padding-left: 25px;'>";

        projeto.objective.forEach(({ title, description }) => {
          body += `<li>`;
          body += `<b>${title}</b> - `;
          body += `${description}`;
          body += "</li>";
        });

        body += "</ul>";
      } else {
        body += `${projeto.objective}`;
      }

      return {
        ...projeto,
        body,
      };
    });
  },
  mounted() {
    window.addEventListener("load", () => {
      // eslint-disable-next-line no-undef
      $(".projetos-cards").slick({
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplaySpeed: 10000,
        autoplay: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 910,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 610,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    });
  },
};
</script>

<style scoped>
.projetos-cards .card {
  transition: all 0.4s ease;
  height: 100%;
}

.projetos-cards .card:hover {
  transform: scale(0.9);
}

.card-title {
  font-size: 15px !important;
}

.card-body {
  padding: 1em 1.25em 1em;
}

.card-projetos {
  width: 16em;
}
</style>
