<template>
  <main class="main">
    <section class="section section-contato">
      <div class="form-contato">
        <form action="#" class="form" method="POST">
          <div>
            <h2>Envie sua mensagem.</h2>
          </div>
          <div class="form-group">
            <label for="nome" class="require">Nome</label>
            <input
              type="text"
              id="nome"
              name="nome"
              v-model="contato.nome"
              placeholder="ex: Jose da Silva"
            />
            <!-- <span class="erro"></span> -->
          </div>

          <div class="form-group">
            <label for="E-mail" class="require">E-mail</label>
            <input
              type="email"
              id="E-mail"
              name="email"
              ref="email"
              v-model="contato.email"
              placeholder="ex: josesilva@exemplo.com"
            />
            <!-- <span class="erro"></span> -->
          </div>

          <div class="form-group">
            <label for="Telefone">Telefone <span>(opcional)</span></label>
            <div class="row">
              <div class="col-5">
                <select
                  class="form-select loading"
                  name="pais"
                  id="pais"
                  v-model="contato.pais"
                >
                  <option
                    v-for="(pais, key) in paises"
                    :key="key"
                    :value="pais.nome"
                    :selected="pais.nome === 'Brasil'"
                  >
                    {{ pais.nome }} + ({{ new Number(pais.fone) }})
                  </option>
                </select>
              </div>
              <div class="col">
                <input
                  type="text"
                  id="Telefone"
                  v-model.number="contato.telefone"
                  name="telefone"
                  placeholder="ex: 99 99999-9999"
                />
              </div>
            </div>
            <!-- <span class="erro"></span> -->
          </div>

          <div class="form-group">
            <label for="Mensagem" class="require">Mensagem</label>
            <textarea
              id="Mensagem"
              name="mensagem"
              cols="20"
              rows="10"
              placeholder="ex: Mensagem de exemplo"
              v-model="contato.mensagem"
            ></textarea>
            <!-- <span class="erro"></span> -->
          </div>

          <div class="form-group">
            <div id="recaptch"></div>
            <!-- <span class="erro"></span> -->
          </div>
          <div class="form-group">
            <span style="color: red">*</span> Campo obrigatório
          </div>

          <button
            class="button button-fill button-enviar"
            @click="enviar($event)"
            ref="buttonEnviar"
          >
            Enviar
          </button>
        </form>
        <div class="contato">
          <EnderecoView :showMaps="false" class-section="endereco-contato" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import EnderecoView from "@/components/EnderecoView.vue";

import paises from "@/assets/js/json/countries.json";
import Swal from "sweetalert2";
import serviceAxios from "@/core/services/service.axios.js";
// import constants from "@/core/Utils/constants";

// import serviceAxios from "@/core/services/service.axios.js";
// let validateRecaptcha = null;
// let checarRecaptcha = async function (response) {
//   if (response.length > 0) {
//     validateRecaptcha = true;
//   } else {
//     validateRecaptcha = false;
//   }
// };

// eslint-disable-next-line no-unused-vars
// var onloadCallback = function () {
//   // eslint-disable-next-line no-undef
//   grecaptcha.render("recaptch", {
//     sitekey: constants.KEY_CAPTCHA,
//     callback: checarRecaptcha,
//   });
// };

export default {
  name: "ContatoView",
  components: { EnderecoView },
  data: function () {
    return {
      contato: {},
      paises,
    };
  },
  methods: {
    async enviar(evt) {
      evt.preventDefault();
      this.$refs.buttonEnviar.innerHTML = "Enviando...";
      this.$refs.buttonEnviar.disabled = true;
      if (this.validarFormulario()) {
        try {
          const form = new FormData();

          for (const contato in this.contato) {
            form.append(contato, this.contato[contato]);
          }

          const response = await serviceAxios.post("sendMail.php", form);

          if (response.data.success) {
            Swal.fire({
              title: "Enviado com sucesso!",
              text: "Seu e-mail foi enviado com sucesso.\nEm breve, entraremos em contato.\nObrigado por visitar nosso site.",
              icon: "success",
              button: "Ok",
            });
          }
        } catch (e) {
          document.querySelector(".button").removeAttribute("disabled");
          document.querySelector(".button").innerHTML = "Enviar";
          if (e.response.data) {
            const { error } = e.response.data;
            Swal.fire({
              title: "Ocorreu um erro. :(",
              text: error,
              icon: "error",
              button: "Ok",
            });
          }
        } finally {
          this.$refs.buttonEnviar.innerHTML = "Enviar";
          this.$refs.buttonEnviar.disabled = false;
        }
      } else {
        this.$refs.buttonEnviar.innerHTML = "Enviando...";
        this.$refs.buttonEnviar.disabled = true;
      }
    },
    validarFormulario() {
      const { nome, email, pais, mensagem } = this.contato;
      let { telefone } = this.contato;
      const erros = [];

      if (!nome) {
        erros.push("Por favor, preencha o campo 'nome'.");
      }

      if (!email) {
        erros.push("Por favor, preencha o campo 'E-mail'");
      } else if (this.$refs.email.validity.typeMismatch) {
        erros.push("Por favor, digite um endereço de e-mail valido.");
      }

      if (!mensagem) {
        erros.push("Por favor, preencha o campo 'Mensagem'");
      }

      // if (!validateRecaptcha) {
      //   erros.push("Por favor, marque o reCAPTCHA");
      // }

      if (!telefone) {
        telefone = `+${pais}${telefone}`;
      } else {
        telefone = "Não informado.";
      }

      if (erros.length) {
        Swal.fire({
          title: "Atenção",
          text: "Você será redirecionado para o site 'academia.edu'.",
          icon: "warning",
          html: erros.join("<br>"),
        });
      }

      return !erros.length;
      // if (countSpanEmpty === 0 && validateRecaptcha) {
      //   document.querySelector(".button").setAttribute("disabled", "true");
      //   document.querySelector(".button").innerHTML = "Enviando ...";

      //   const data = new FormData();
      //   data.append("nome", nome.value);
      //   data.append("email", email.value);
      //   data.append("mensagem", mensagem.value);
      //   data.append("telefone", telefone);

      //   // enviarEmail(data);
      // }
    },
  },
};
</script>

<style scoped>
.button-enviar {
  margin-top: 10px;
  padding: 10px 30px;
}

.form-contato .contato {
  width: 40%;
  margin-left: 40px;
  height: 100%;
  position: relative;
  border-left: 2px solid #2274a582;
  padding: 30px;
}

.form-contato {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.form-select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  width: 90%;
  height: 100%;
  border: 2px solid #2576a5;
  border-radius: 6px;
  outline: none;
  resize: none;
  transition: all 0.5s ease;
  padding: 10px;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: transparent;
}
</style>
