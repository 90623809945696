<template>
  <section class="section" id="clientes">
    <h2>Nossos clientes</h2>

    <div class="slide-customers">
      <div v-for="(cliente, key) of clientes" :key="key" class="card-custumers">
        <div
          class="link-customers img-customers"
          :style="{ backgroundImage: `url(${getImages(cliente.img)})` }"
        >
          <a target="_blank" :href="cliente.link" :title="cliente.custumer">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import slickCarousel from "slick-carousel";
import clientes from "@/assets/js/json/clientes.json";
import { getImages } from "@/core/Utils";

export default {
  name: "ClientesView",
  data: () => ({
    clientes,
  }),
  mounted() {
    // eslint-disable-next-line no-undef
    $(".slide-customers").slick({
      dots: false,
      slidesToShow: 4,
      autoplaySpeed: 6000,
      autoplay: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  },
  methods: {
    getImages,
  },
};
</script>
<style scoped>
.img-customers {
  margin-left: 10px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: 50% 50% !important;
}

.link-customers {
  transform: scale(1);
  transition: 0.5s ease;
}

.link-customers:hover {
  cursor: pointer;
  transform: scale(0.9);
}

.slide-customers a {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0 5px;
  text-align: center;
}

.card-custumers {
  width: 70% !important;
}
.card-custumers > div {
  height: 160px;
}
</style>
<style>
.slick-track {
  height: auto !important;
}
</style>
