<template>
  <div class="loader" v-if="show">
    <img src="../../assets/img/logo_acqua.svg" alt="GIF de loading" />
    <p class="color-text">
      Carregando <span>.</span><span>.</span><span>.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "LoaderView",
  components: {},
  data: () => ({
    show: true,
  }),
  mounted() {
    window.addEventListener("load", () => {
      this.show = false;
    });
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.loader {
  font-size: 1.5em;
}

.loader p span {
  opacity: 1;
  font-size: 2em;
}

.loader p span:nth-of-type(1) {
  font-size: 2em;
  animation-name: dotsDisplay;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 0.33s;
}

.loader p span:nth-of-type(2) {
  font-size: 2em;
  animation-name: dotsDisplay;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 0.66s;
}

.loader p span:nth-of-type(3) {
  font-size: 2em;
  animation-name: dotsDisplay;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes dotsDisplay {
  33.33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
