import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import GalleryView from "../views/GalleryView.vue";
import QuemSomosView from "../views/QuemSomosView.vue";
import ContatoView from "../views/ContatoView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "PaginaInicial",
    component: HomeView,
  },
  {
    path: "/galeria",
    name: "Galeria",
    component: GalleryView,
  },
  {
    path: "/quem-somos",
    name: "QuemSomos",
    component: QuemSomosView,
  },
  {
    path: "/contato",
    name: "Contato",
    component: ContatoView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
